import api, { apiData } from "../utils/api";
import { serviceError } from "../lib/serviceError";
import { utmSourceURL } from "../lib/script";

class ServicesAPI {
  // Enter Mobile Number
  async enterMobileNumber(mobile) {
    try {
      const req = {
        url: "new-api/customers/customer-login-byMsg91" + utmSourceURL(),
        method: "POST",
        data: {
          mobile,
        },
      };
      const res = await apiData(req);
      return res;
    } catch (error) {
      serviceError(error);
    }
  }

  // Enter Mobile Number
  async verifyOTPAPI(mobile, otp) {
    try {
      const req = {
        url: "new-api/customers/verify-otp",
        method: "POST",
        data: {
          mobile,
          otp,
        },
      };

      const res = await apiData(req);
      return res;
    } catch (error) {
      serviceError(error);
    }
  }

  // Enter Mobile Number
  async dashboardAPI() {
    try {
      const req = {
        url: "new-api/customers/dashboard",
        method: "POST",
        data: {
          appVersion: "28.2",
          residenceAddress: "H Block",
          state: "Delhi",
          city: "New Delhi",
          pincode: "110022",
        },
      };
      const res = await apiData(req);
      return res;
    } catch (error) {
      serviceError(error);
    }
  }

  // Enter User Name & Email
  async enterUserNameEmail(name, email) {
    try {
      const endpoint = "new-api/customer_onboarding/onboard_user_NameAndEmail";
      const req = {
        name: name,
        email: email,
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // PAN Verification
  async panVerification(pan) {
    try {
      const req = {
        url: "new-api/customer_onboarding/pan-verification",
        method: "POST",
        data: {
          panNumber: pan.toUpperCase(),
        },
      };

      const res = await apiData(req);

      return res;
    } catch (error) {
      serviceError(error);
    }
  }

  // PAN Confirmation
  async panConfirmation(pan) {
    try {
      const endpoint = "new-api/customer_onboarding/pan-confirmation";
      const req = {
        panNumber: pan,
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Check Aadhaar Status
  async checkAadhaarStatusAPI() {
    try {
      const endpoint = "new-api/common/aadhar-status";
      const req = {};

      const { data } = await api.get(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Generate Aadhaar OTP
  async generateAadhaarOTP(aadhaar) {
    try {
      const req = {
        url: "new-api/customer_onboarding/aadhar-verification-generate-otp",
        method: "POST",
        data: {
          aadharNo: aadhaar,
        },
      };

      const res = await apiData(req);

      return res;
    } catch (error) {
      serviceError(error);
    }
  }

  // Aadhaar Verification OTP
  async aadhaarVerificationOTP(aadhaar, otp) {
    try {
      const endpoint = "new-api/customer_onboarding/aadhar-verification-verify-otp";
      const req = {
        aadharNo: aadhaar,
        otp: otp,
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Aadhaar PAN Verify
  async aadhaarPANVerify() {
    const req = {
      url: "new-api/customer_onboarding/aadhar-pan-verify",
      method: "GET",
      data: {},
    };
    const res = await apiData(req);
    return res;
  }

  // Re-kyc Button Message
  async rekycButtonMessages() {
    const req = {
      url: "new-api/customers/rekycButtonMessages",
      method: "GET",
      data: {},
    };
    const res = await apiData(req);
    return res;
  }

  // PAN Aadhaar Mismatch
  async panAadhaarMismatchClick() {
    const req = {
      url: "new-api/customers/pan-aadhar-mismatch-click",
      method: "GET",
      data: {},
    };
    const res = await apiData(req);
    return res;
  }

  async aadhaarPanReverify() {
    const req = {
      url: "new-api/customer_onboarding/aadhar-pan-reverify",
      method: "GET",
      data: {},
    };
    const res = await apiData(req);
    return res;
  }

  // Aadhaar Verification Digilocker
  async aadhaarVerificationInitiateDigilocker() {
    const host = window.location.href.split("/");

    try {
      const endpoint = "new-api/customer_onboarding/aadhar-verification-initiate-digilocker";
      const req = {
        callBackUrl: host[0] + "//" + host[2] + "/digilocker-verify",
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Aadhaar Verification Webhook Digilocker
  async aadhaarVerificationWebhookDigilocker(params) {
    try {
      const endpoint = `new-api/customer_onboarding/aadhar-verification-webhook-digilocker?code=${params.code}&state=${params.state}`;
      const req = {};

      const { data } = await api.get(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Incomplete Details Update
  async incompleteDetailsUpdate(data) {
    const { key, value } = data;

    try {
      const endpoint = "new-api/customers/incompleteDetailsUpdate";
      const req = {
        [key]: value,
        leadID: Number(localStorage.getItem("leadID")),
      };

      const { data } = await api.put(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Enter Your Residence
  async enterYourResidence(data) {
    const { key, value } = data;
    try {
      const req = {
        url: "new-api/customers/incompleteDetailsUpdate",
        method: "PUT",
        data: {
          [key]: value,
          leadID: Number(localStorage.getItem("leadID")),
        },
      };
      const res = await apiData(req);
      return res;
    } catch (error) {
      console.log("Error:", error);
    }
  }

  // Current Address
  async currentAddressUpdate(values, repeatCustomer) {
    const { houseNo, city, state, pinCode, landmark } = values;

    const host = window.location.href.split("/");

    try {
      const endpoint = "new-api/customers/incompleteDetailsUpdate";
      const req = {
        residenceAddress: `${houseNo} ${city} ${state} ${pinCode}`,
        state: state,
        city: city,
        pincode: `${pinCode}`,
        landmark: landmark,
        leadID: Number(localStorage.getItem("leadID")),
        finboxCallBackUrl: host[0] + "//" + host[2] + "/finbox",
        repeat: repeatCustomer,
      };

      const { data } = await api.put(endpoint, req);
      return data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      serviceError(error);
    }
  }

  // Get States
  async getStates(value) {
    try {
      const endpoint = `new-api/customer_onboarding/getStates?searchWord=${value.toLowerCase()}`;
      const req = {};
      const { data } = await api.get(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Get Address
  async getAddressAPI() {
    try {
      const endpoint = "new-api/customers/address";
      const req = {};
      const { data } = await api.get(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }
}

export default ServicesAPI;
