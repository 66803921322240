import axios from "axios";
import { getToken } from "./storage";
import { getEnvironment } from "./environment";
// import Swal from "sweetalert2";

const { API_URL } = getEnvironment();

export const CancelToken = axios.CancelToken;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// API Request Interceptor
api.interceptors.request.use(
  (config) => {
    const token = getToken();

    // Block the api call if 'access-token' is unavailable
    if (!token) {
      const cancelToken = new CancelToken((cancel) => cancel("Rejected"));
      return { ...config, cancelToken };
    }

    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error)
);

// Add Response Interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes outside
    if (error.response && (error.response.status === 403 || error.response.statusText === "Unauthorized")) {
      // Handle 401 error (Unauthorized)
      console.log("Unauthorized, logging out...");
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

export async function apiData(req, contentType = "application/json") {
  const { url, method, data } = req;

  try {
    const res = await axios({
      url: API_URL + url,
      method: method,
      data: data,
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return res.data;
  } catch (error) {
    // Handle 401 error (Unauthorized)
    if (error.response && (error.response.status === 403 || error.response.statusText === "Unauthorized")) {
      window.location.href = "/logout";
    }

    console.log("Failed to fetch data", error);
    return error.response.data;
  }
}

export default api;
