import { getEnvironment } from "../utils/environment";

const { PRODUCT } = getEnvironment();

// Themes
export const themes = {
  ramfincorp: {
    primaryColor: "#182bda",
    secondaryColor: "#4051eb",
    lightColor: "#ebebfa",
    darkColor: "#020A4F",
  },
  kamakshi: {
    primaryColor: "#1EAD48",
    secondaryColor: "#93D36A",
    lightColor: "#1EAD481A",
    darkColor: "#024F24",
    // primaryColor: "#860002",
    // secondaryColor: "#db4a0f",
  },
};

export const selectedTheme = () => {
  if (PRODUCT === "ramfincorp_api") {
    return themes.ramfincorp;
  } else {
    return themes.kamakshi;
  }
};
