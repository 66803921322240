import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ListItemText, MenuItem, MenuList, Typography } from "@mui/material";

export default function SelfiePermissionModal({ openModal, setOpenModal }) {
  return (
    <React.Fragment>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Enable Camera Permission"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant={"subtitle1"} sx={{ mb: 2 }}>
              Camera access is denied. Please enable it in your browser settings.
            </Typography>
            <Typography variant={"body2"} sx={{ fontWeight: "bold" }}>
              Instruct the User to Reset Permissions:
            </Typography>
            <MenuList dense>
              <MenuItem>
                <ListItemText>1. Click the 🔒 lock icon in the address bar.</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>2. Find the 🎥 "Camera" option.</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>3. Change it to "Allow."</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>4. Refresh the page.</ListItemText>
              </MenuItem>
            </MenuList>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Agree</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
